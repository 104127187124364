@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;800&display=swap);
#calc-root {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    overflow: hidden;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}

#calc-root a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}
#calc-root a:active,
#calc-root a:hover {
    outline-width: 0;
}
#calc-root abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
            text-decoration: underline dotted;
}
#calc-root b,
#calc-root strong {
    font-weight: inherit;
    font-weight: bolder;
}
#calc-root small {
    font-size: 80%;
}

#calc-root img {
    border-style: none;
}
#calc-root svg:not(:root) {
    overflow: hidden;
}

#calc-root button,
#calc-root input,
#calc-root optgroup,
#calc-root select,
#calc-root textarea {
    font: inherit;
    margin: 0;
}
#calc-root button,
#calc-root input {
    overflow: visible;
}
#calc-root button,
#calc-root select {
    text-transform: none;
}
#calc-root [type="reset"],
#calc-root [type="submit"],
#calc-root button,
#calc-root html [type="button"] {
    -webkit-appearance: button;
}
#calc-root [type="button"]::-moz-focus-inner,
#calc-root [type="reset"]::-moz-focus-inner,
#calc-root [type="submit"]::-moz-focus-inner,
#calc-root button::-moz-focus-inner {
    border-style: none;
    padding: 0;
}
#calc-root [type="button"]:-moz-focusring,
#calc-root [type="reset"]:-moz-focusring,
#calc-root [type="submit"]:-moz-focusring,
#calc-root button:-moz-focusring {
    outline: 1px dotted ButtonText;
}
#calc-root fieldset {
    border: 1px solid silver;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}
#calc-root legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}
#calc-root textarea {
    overflow: auto;
}
#calc-root [type="checkbox"],
#calc-root [type="radio"] {
    box-sizing: border-box;
    padding: 0;
}
#calc-root [type="number"]::-webkit-inner-spin-button,
#calc-root [type="number"]::-webkit-outer-spin-button {
    height: auto;
}

#calc-root ::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.54;
}

#calc-root {
    box-sizing: border-box;
    overflow-y: scroll;
}
#calc-root * {
    box-sizing: inherit;
}
#calc-root *:before {
    box-sizing: inherit;
}
#calc-root *:after {
    box-sizing: inherit;
}
#calc-root {
    color: hsla(0, 0%, 0%, 0.8);
    font-family: "Roboto", serif;
    font-weight: normal;
    word-wrap: break-word;
    -webkit-font-kerning: normal;
            font-kerning: normal;
    -ms-font-feature-settings: "kern", "liga", "clig", "calt";
    font-feature-settings: "kern", "liga", "clig", "calt", "kern";
}
#calc-root img {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
}
#calc-root ul {
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    list-style-position: outside;
    list-style-image: none;
}
#calc-root ol {
    margin-left: 1.45rem;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
    list-style-position: outside;
    list-style-image: none;
}
#calc-root dl {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
}
#calc-root dd {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
}
#calc-root p {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
}

#calc-root table {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
    font-size: 1rem;
    line-height: 1.45rem;
    border-collapse: collapse;
    width: 100%;
}
#calc-root fieldset {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
}
#calc-root th {
    font-weight: bold;
}
#calc-root li {
    margin-bottom: calc(1.45rem / 2);
}
#calc-root ol li {
    padding-left: 0;
}
#calc-root ul li {
    padding-left: 0;
}
#calc-root li > ol {
    margin-left: 1.45rem;
    margin-bottom: calc(1.45rem / 2);
    margin-top: calc(1.45rem / 2);
}
#calc-root li > ul {
    margin-left: 1.45rem;
    margin-bottom: calc(1.45rem / 2);
    margin-top: calc(1.45rem / 2);
}
blockquote *:last-child {
    margin-bottom: 0;
}
#calc-root li *:last-child {
    margin-bottom: 0;
}
#calc-root p *:last-child {
    margin-bottom: 0;
}
#calc-root li > p {
    margin-bottom: calc(1.45rem / 2);
}

#calc-root thead {
    text-align: left;
}
#calc-root td,
#calc-root th {
    text-align: left;
    border-bottom: 1px solid hsla(0, 0%, 0%, 0.12);
    font-feature-settings: "tnum";
    -moz-font-feature-settings: "tnum";
    -ms-font-feature-settings: "tnum";
    -webkit-font-feature-settings: "tnum";
    padding-left: 0.96667rem;
    padding-right: 0.96667rem;
    padding-top: 0.725rem;
    padding-bottom: calc(0.725rem - 1px);
}
#calc-root th:first-child,
#calc-root td:first-child {
    padding-left: 0;
}
#calc-root th:last-child,
#calc-root td:last-child {
    padding-right: 0;
}

@media only screen and (max-width: 480px) {
    #calc-root {
        font-size: 100%;
    }
}

.react-datepicker {
    font-family: "Montserrat", sans-serif !important;
}

.react-datepicker__day--selected,
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: #5468ff !important;
}

@media (max-width: 400px) {
    .react-datepicker__month-container {
        width: 233px !important;
    }
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
        font-size: 13px !important;
    }
    .react-datepicker__time-container
        .react-datepicker__time
        .react-datepicker__time-box,
    .react-datepicker__time-container {
        width: 65px !important;
    }

    .react-datepicker-popper {
        left: -8px !important;
    }
}

.App {
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
    padding: 15px;
}



.calc_calcWrapper__115Ep {
    padding: 30px;
    font-family: "Montserrat", sans-serif;
}

.calc_calcWrapper__115Ep * {
    box-sizing: border-box;
}

.calc_dark__1NwLZ {
    background-color: #001627;
    color: #fff;
}

.calc_dark__1NwLZ .calc_fieldInput__3iaVR,
.calc_dark__1NwLZ .calc_radioFieldGroup__DzsvK label:before {
    color: #fff;
    background: rgba(255, 255, 255, 0.11);
}
.calc_dark__1NwLZ option {
    color: #01213a;
}

.calc_dark__1NwLZ .calc_fieldInput__3iaVR:focus {
    box-shadow: 0 0 2px 2px #f66d9b;
}

.calc_dark__1NwLZ .calc_totalPrice__DoAGS,
.calc_dark__1NwLZ .calc_bonusTime__3ewjI,
.calc_dark__1NwLZ .calc_radioFieldGroup__DzsvK input:checked ~ .calc_radioFieldLabel__2_fWN,
.calc_dark__1NwLZ .calc_radioFieldLabel__2_fWN:hover {
    color: #f66d9b;
}

.calc_dark__1NwLZ .calc_radioFieldGroup__DzsvK input:hover + label:before,
.calc_dark__1NwLZ .calc_radioFieldGroup__DzsvK input:checked + label:before {
    background: #ee5286;
}

.calc_textPrimary__3oCmf {
    color: #ee5286;
}

.calc_formHeader__1dvUp {
    font-size: 1.5em;
    text-align: center;
    margin-bottom: 30px;
    font-weight: bold;
    letter-spacing: 1px;
}

.calc_fieldsWrapper__1RUzH {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.calc_fieldGroup__2xhfp {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    width: 31%;
}

.calc_fieldGroupButton__3h3e7 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 31%;
}

.calc_fieldLabel__WotYt {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 6px;
    letter-spacing: 1px;
    font-family: "Montserrat", sans-serif;
}

.calc_fieldError___o8MP {
    color: #ee223d;
    font-size: 14px;
    line-height: 1.3;
}

.calc_fieldWarning__2Gf6i {
    color: #ff8050;
    font-size: 14px;
    line-height: 1.3;
}

.calc_fieldInput__3iaVR {
    padding: 9px 15px;
    height: 45px;
    letter-spacing: 0.5px;
    background: none;
    background: rgba(1, 33, 58, 0.1);
    border: none;
    border-radius: 9px;
    transition: 0.2s;
    width: 100%;
}

.calc_fieldInput__3iaVR[disabled] ~ label {
    display: none;
}

.calc_fieldInput__3iaVR option[disabled] {
    display: none;
}

input[type="radio"].calc_fieldInput__3iaVR {
    width: 1px;
}

.calc_fieldInput__3iaVR:focus {
    box-shadow: 0 0 2px 2px #694ae6;
    outline: none;
}

.calc_radioFieldGroupContainer__L34EV {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.calc_radioFieldGroup__DzsvK {
    width: 48%;
    margin-bottom: 9px;
}

.calc_radioFieldGroup__DzsvK input {
    visibility: hidden;
    position: absolute;
}

.calc_radioFieldGroup__DzsvK label {
    position: relative;
    margin-left: 25px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.calc_radioFieldGroup__DzsvK label:before {
    content: "";
    width: 18px;
    height: 18px;
    position: absolute;
    left: -25px;
    top: 0;
    border-radius: 50%;
    border: 1px solid rgba(1, 33, 58, 0);
    background: rgba(1, 33, 58, 0.1);
    border-width: 5px;
    transition: 0.2s;
}

.calc_radioFieldGroup__DzsvK input:hover + label:before {
    border: 5px solid #e5e8eb;
}

.calc_radioFieldGroup__DzsvK input:hover + label,
.calc_radioFieldGroup__DzsvK input:checked + label {
    color: #5468ff;
}

.calc_radioFieldGroup__DzsvK input:checked + label:before {
    opacity: 1;
    border: 5px solid #e5e8eb;
    background: #5468ff;
}

.calc_radioFieldGroup__DzsvK label {
    cursor: pointer;
}

.calc_radioFieldLabel__2_fWN {
    font-size: 1em;
    text-transform: none !important;
    font-family: "Montserrat", sans-serif;
}

.calc_totalPrice__DoAGS {
    font-weight: 700;
    font-size: 2em;
    color: #5468ff;
    line-height: 1.1;
    font-family: "Montserrat", sans-serif;
}

.calc_bonusTime__3ewjI {
    font-size: 2em;
    font-weight: 700;
    color: #5468ff;
    line-height: 1.1;
    font-family: "Montserrat", sans-serif;
}

@media (max-width: 560px) {
    .calc_fieldGroup__2xhfp,
    .calc_fieldGroupButton__3h3e7 {
        width: 100%;
    }
}

.Button_Button__2C4am {
    height: 48px;
    border-radius: 999px;
    border: none;
    cursor: pointer;
    transition: 0.2s ease;
    will-change: box-shadow, transform;
    /* text-transform: uppercase; */
    letter-spacing: 0.5px;
    font-weight: 400;
    font-size: 1rem;
    text-shadow: 0 1px 0 #4b5ef0;
    box-shadow: 0 -5px 30px -3px rgba(105, 74, 230, 0.3),
        0 2px 4px 0 rgba(105, 74, 230, 0.4);
    color: #fff;
    /* background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#aeb7ff),
      to(#5468ff)
    )
    no-repeat;
  background: linear-gradient(#99b1f1, #5468ff) no-repeat; */
    background: #694ae6;
    /* box-shadow: 0 7px 13px -3px rgba(45, 35, 66, 0.3),
        0 2px 4px 0 rgba(45, 35, 66, 0.4), inset 0 -2px 0 0 #4b58ba; */
    /* border-bottom: 1px solid #4b58ba; */
}

.Button_Button__2C4am:hover,
.Button_Button__2C4am:focus {
    /* box-shadow: 0 11px 16px -3px rgba(45, 35, 66, 0.3),
        0 4px 5px 0 rgba(56, 54, 61, 0.4), inset 0 -2px 0 0 #4b58ba; */
    background: #613df1;
    transform: translateY(-1px);
}

.Button_Button__2C4am:active {
    background-blend-mode: multiply, normal;
    /* box-shadow: inset 0 2px 0 1px rgba(132, 138, 184, 0.11),
        inset 0 2px 9px 0 rgba(93, 100, 148, 0.5), inset 0 -1px 0 1px #4b58ba; */
    transform: translateY(1px);
    transition: none;
}

