@value colors: "../variables.css";
@value gaps: "../variables.css";
@value darker_blue, dark_blue from colors;
@value x15, x10, x5, x3 from gaps;

.Button {
    height: 48px;
    border-radius: 999px;
    border: none;
    cursor: pointer;
    transition: 0.2s ease;
    will-change: box-shadow, transform;
    /* text-transform: uppercase; */
    letter-spacing: 0.5px;
    font-weight: 400;
    font-size: 1rem;
    text-shadow: 0 1px 0 #4b5ef0;
    box-shadow: 0 -5px 30px -3px rgba(105, 74, 230, 0.3),
        0 2px 4px 0 rgba(105, 74, 230, 0.4);
    color: #fff;
    /* background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#aeb7ff),
      to(#5468ff)
    )
    no-repeat;
  background: linear-gradient(#99b1f1, #5468ff) no-repeat; */
    background: #694ae6;
    /* box-shadow: 0 7px 13px -3px rgba(45, 35, 66, 0.3),
        0 2px 4px 0 rgba(45, 35, 66, 0.4), inset 0 -2px 0 0 #4b58ba; */
    /* border-bottom: 1px solid #4b58ba; */
}

.Button:hover,
.Button:focus {
    /* box-shadow: 0 11px 16px -3px rgba(45, 35, 66, 0.3),
        0 4px 5px 0 rgba(56, 54, 61, 0.4), inset 0 -2px 0 0 #4b58ba; */
    background: #613df1;
    transform: translateY(-1px);
}

.Button:active {
    background-blend-mode: multiply, normal;
    /* box-shadow: inset 0 2px 0 1px rgba(132, 138, 184, 0.11),
        inset 0 2px 9px 0 rgba(93, 100, 148, 0.5), inset 0 -1px 0 1px #4b58ba; */
    transform: translateY(1px);
    transition: none;
}
