@value colors: "./variables.css";
@value gaps: "./variables.css";
@value dark_blue, blue, dark_purple, pink, yellow, red, orange from colors;
@value x15, x10, x5, x3, x2 from gaps;

.calcWrapper {
    padding: x10;
    font-family: "Montserrat", sans-serif;
}

.calcWrapper * {
    box-sizing: border-box;
}

.dark {
    background-color: #001627;
    color: #fff;
}

.dark .fieldInput,
.dark .radioFieldGroup label:before {
    color: #fff;
    background: rgba(255, 255, 255, 0.11);
}
.dark option {
    color: #01213a;
}

.dark .fieldInput:focus {
    box-shadow: 0 0 2px 2px #f66d9b;
}

.dark .totalPrice,
.dark .bonusTime,
.dark .radioFieldGroup input:checked ~ .radioFieldLabel,
.dark .radioFieldLabel:hover {
    color: #f66d9b;
}

.dark .radioFieldGroup input:hover + label:before,
.dark .radioFieldGroup input:checked + label:before {
    background: #ee5286;
}

.textPrimary {
    color: #ee5286;
}

.formHeader {
    font-size: 1.5em;
    text-align: center;
    margin-bottom: x10;
    font-weight: bold;
    letter-spacing: 1px;
}

.fieldsWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.fieldGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: x5;
    width: 31%;
}

.fieldGroupButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 31%;
}

.fieldLabel {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: x2;
    letter-spacing: 1px;
    font-family: "Montserrat", sans-serif;
}

.fieldError {
    color: red;
    font-size: 14px;
    line-height: 1.3;
}

.fieldWarning {
    color: orange;
    font-size: 14px;
    line-height: 1.3;
}

.fieldInput {
    padding: x3 x5;
    height: x15;
    letter-spacing: 0.5px;
    background: none;
    background: rgba(1, 33, 58, 0.1);
    border: none;
    border-radius: x3;
    transition: 0.2s;
    width: 100%;
}

.fieldInput[disabled] ~ label {
    display: none;
}

.fieldInput option[disabled] {
    display: none;
}

input[type="radio"].fieldInput {
    width: 1px;
}

.fieldInput:focus {
    box-shadow: 0 0 2px 2px #694ae6;
    outline: none;
}

.radioFieldGroupContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.radioFieldGroup {
    width: 48%;
    margin-bottom: x3;
}

.radioFieldGroup input {
    visibility: hidden;
    position: absolute;
}

.radioFieldGroup label {
    position: relative;
    margin-left: 25px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.radioFieldGroup label:before {
    content: "";
    width: 18px;
    height: 18px;
    position: absolute;
    left: -25px;
    top: 0;
    border-radius: 50%;
    border: 1px solid rgba(1, 33, 58, 0);
    background: rgba(1, 33, 58, 0.1);
    border-width: 5px;
    transition: 0.2s;
}

.radioFieldGroup input:hover + label:before {
    border: 5px solid #e5e8eb;
}

.radioFieldGroup input:hover + label,
.radioFieldGroup input:checked + label {
    color: #5468ff;
}

.radioFieldGroup input:checked + label:before {
    opacity: 1;
    border: 5px solid #e5e8eb;
    background: #5468ff;
}

.radioFieldGroup label {
    cursor: pointer;
}

.radioFieldLabel {
    font-size: 1em;
    text-transform: none !important;
    font-family: "Montserrat", sans-serif;
}

.totalPrice {
    font-weight: 700;
    font-size: 2em;
    color: #5468ff;
    line-height: 1.1;
    font-family: "Montserrat", sans-serif;
}

.bonusTime {
    font-size: 2em;
    font-weight: 700;
    color: #5468ff;
    line-height: 1.1;
    font-family: "Montserrat", sans-serif;
}

@media (max-width: 560px) {
    .fieldGroup,
    .fieldGroupButton {
        width: 100%;
    }
}
