@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;800&display=swap");

#calc-root {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    overflow: hidden;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}

#calc-root a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}
#calc-root a:active,
#calc-root a:hover {
    outline-width: 0;
}
#calc-root abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
}
#calc-root b,
#calc-root strong {
    font-weight: inherit;
    font-weight: bolder;
}
#calc-root small {
    font-size: 80%;
}

#calc-root img {
    border-style: none;
}
#calc-root svg:not(:root) {
    overflow: hidden;
}

#calc-root button,
#calc-root input,
#calc-root optgroup,
#calc-root select,
#calc-root textarea {
    font: inherit;
    margin: 0;
}
#calc-root button,
#calc-root input {
    overflow: visible;
}
#calc-root button,
#calc-root select {
    text-transform: none;
}
#calc-root [type="reset"],
#calc-root [type="submit"],
#calc-root button,
#calc-root html [type="button"] {
    -webkit-appearance: button;
}
#calc-root [type="button"]::-moz-focus-inner,
#calc-root [type="reset"]::-moz-focus-inner,
#calc-root [type="submit"]::-moz-focus-inner,
#calc-root button::-moz-focus-inner {
    border-style: none;
    padding: 0;
}
#calc-root [type="button"]:-moz-focusring,
#calc-root [type="reset"]:-moz-focusring,
#calc-root [type="submit"]:-moz-focusring,
#calc-root button:-moz-focusring {
    outline: 1px dotted ButtonText;
}
#calc-root fieldset {
    border: 1px solid silver;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}
#calc-root legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}
#calc-root textarea {
    overflow: auto;
}
#calc-root [type="checkbox"],
#calc-root [type="radio"] {
    box-sizing: border-box;
    padding: 0;
}
#calc-root [type="number"]::-webkit-inner-spin-button,
#calc-root [type="number"]::-webkit-outer-spin-button {
    height: auto;
}

#calc-root ::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.54;
}

#calc-root {
    box-sizing: border-box;
    overflow-y: scroll;
}
#calc-root * {
    box-sizing: inherit;
}
#calc-root *:before {
    box-sizing: inherit;
}
#calc-root *:after {
    box-sizing: inherit;
}
#calc-root {
    color: hsla(0, 0%, 0%, 0.8);
    font-family: "Roboto", serif;
    font-weight: normal;
    word-wrap: break-word;
    font-kerning: normal;
    -moz-font-feature-settings: "kern", "liga", "clig", "calt";
    -ms-font-feature-settings: "kern", "liga", "clig", "calt";
    -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
    font-feature-settings: "kern", "liga", "clig", "calt";
}
#calc-root img {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
}
#calc-root ul {
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    list-style-position: outside;
    list-style-image: none;
}
#calc-root ol {
    margin-left: 1.45rem;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
    list-style-position: outside;
    list-style-image: none;
}
#calc-root dl {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
}
#calc-root dd {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
}
#calc-root p {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
}

#calc-root table {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
    font-size: 1rem;
    line-height: 1.45rem;
    border-collapse: collapse;
    width: 100%;
}
#calc-root fieldset {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
}
#calc-root th {
    font-weight: bold;
}
#calc-root li {
    margin-bottom: calc(1.45rem / 2);
}
#calc-root ol li {
    padding-left: 0;
}
#calc-root ul li {
    padding-left: 0;
}
#calc-root li > ol {
    margin-left: 1.45rem;
    margin-bottom: calc(1.45rem / 2);
    margin-top: calc(1.45rem / 2);
}
#calc-root li > ul {
    margin-left: 1.45rem;
    margin-bottom: calc(1.45rem / 2);
    margin-top: calc(1.45rem / 2);
}
blockquote *:last-child {
    margin-bottom: 0;
}
#calc-root li *:last-child {
    margin-bottom: 0;
}
#calc-root p *:last-child {
    margin-bottom: 0;
}
#calc-root li > p {
    margin-bottom: calc(1.45rem / 2);
}

#calc-root thead {
    text-align: left;
}
#calc-root td,
#calc-root th {
    text-align: left;
    border-bottom: 1px solid hsla(0, 0%, 0%, 0.12);
    font-feature-settings: "tnum";
    -moz-font-feature-settings: "tnum";
    -ms-font-feature-settings: "tnum";
    -webkit-font-feature-settings: "tnum";
    padding-left: 0.96667rem;
    padding-right: 0.96667rem;
    padding-top: 0.725rem;
    padding-bottom: calc(0.725rem - 1px);
}
#calc-root th:first-child,
#calc-root td:first-child {
    padding-left: 0;
}
#calc-root th:last-child,
#calc-root td:last-child {
    padding-right: 0;
}

@media only screen and (max-width: 480px) {
    #calc-root {
        font-size: 100%;
    }
}

.react-datepicker {
    font-family: "Montserrat", sans-serif !important;
}

.react-datepicker__day--selected,
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: #5468ff !important;
}

@media (max-width: 400px) {
    .react-datepicker__month-container {
        width: 233px !important;
    }
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
        font-size: 13px !important;
    }
    .react-datepicker__time-container
        .react-datepicker__time
        .react-datepicker__time-box,
    .react-datepicker__time-container {
        width: 65px !important;
    }

    .react-datepicker-popper {
        left: -8px !important;
    }
}
